import logo1 from "./img/euro.png";
import "./App.css";
import styles from "./App.module.css";
import { CiMobile3 } from "react-icons/ci";

function App() {
  return (
    <div className={styles.mainAppContainer}>
      <div className={styles.wrapperContainer}>
        <div className={styles.textContainerConstruction}>
          <img src={logo1} className={styles.logoImg} alt="logo" />
        </div>
        <div className={styles.textContainerConstruction}>
          <span className={styles.underConstruction}>COMING SOON</span>
          <span className={styles.infoConstruction}>
            Our website is currently under construction. We should be ready
            soon!
          </span>
          <div className={styles.infoContainer}>
            <div className={styles.phoneContainer}>
              <CiMobile3 color="#8fc53c" className={styles.phoneIcon} />
              <span className={styles.phone}>+355692035633</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
